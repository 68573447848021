import NoticeModel from '@/views/landing/notice/model/NoticeModel';
import router from '@/router';
import store from '@lemontree-ai/lemontree-admin-common-front/store/index';

export default class NoticeDetailViewModel {
  constructor() {
    this.model = {};
    this.isModify = false;
  }

  init(id){
    this.model = new NoticeModel();
    if(id){
      this.getNoticeDetail(id)
    }
  }

  onClickRow(){
    router.push({ name: 'APP_NOTICE_DETAIL' });
  }

  onClickDelete(){
    const requestDelete = () => {
      router.push({ name: 'APP_NOTICE_LIST' });
      store.dispatch('commonToast/fetchToastStart', `공지사항이 삭제되었습니다.`);
    }
    store.dispatch('commonAlert/fetchAlertStart', {
      alertMsg: `공지사항을 정말 삭제하시겠습니까?`,
      closeBtnText: '취소',
      compeleteBtnText: '확인',
      confirmCallBack: requestDelete,
    });
  }
  
  onClickComplete(){
    let toastMessage = '';
    let routeName = '';
    if(this.isModify){
      toastMessage = '수정';
      routeName = 'APP_NOTICE_DETAIL';
    }else{
      toastMessage = '등록';
      routeName = 'APP_NOTICE_LIST';
    }
    store.dispatch('commonToast/fetchToastStart', `공지사항 ${toastMessage}이 완료되었습니다.`);
    router.push({ name: routeName });
  }

  getNoticeDetail(id){
    const resultData = {id:id, title:'공지사항 제목이 들어갑니다.', content:'공지사항 내용이 들어갑니다. 공지사항 내용이 들어갑니다. 공지사항 내용이 들어갑니다.', isFixed:true}
    this.model.setData(resultData);
  }
}