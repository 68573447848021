export default class NoticeModel {
  constructor(){
    this.objRaw = {};
    this.id = '';
    this.title = '';
    this.content = '';
    this.isFixed = false;
  }
  setData(obj){
    if( obj ) this.objRaw = obj;
    const { id, title, content, isFixed } = obj;
    this.id = id;
    this.title = title;
    this.content = content;
    this.isFixed = isFixed;
  }
  getData(){
    let obj = {
      id : this.id,
      title : this.title,
      content : this.content,
      isFixed : this.isFixed,
    }
    return obj;
  }
}