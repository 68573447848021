<template>
  <PageWithLayout>
    <TableView>
      <template v-slot:tbl_colgroup>
        <col style="width:130px"/>
        <col/>
      </template>
      <template v-slot:tbl_top>
        <div class="area_left">
          <strong class="tit_tbl">공지사항 상세</strong>
        </div>
      </template>
      <template v-slot:tbl_body>
        <tr>
          <th>고정</th>
          <td>
            <span class="txt_tbl">{{ viewModel.model.isFixed ? '고정' : '미고정' }}</span>
          </td>
        </tr>
        <tr>
          <th>제목</th>
          <td>
            <span class="txt_tbl">{{ viewModel.model.title }}</span>
          </td>
        </tr>
        <tr>
          <th>내용</th>
          <td>
            <span class="txt_tbl">{{ viewModel.model.content }}</span>
          </td>
        </tr>
      </template>
    </TableView>
    <Sticky>
      <div class="area_left">
        <Button
          btnSize="medium"
          btnStyle="secondary_border"
          text="목록"
          routeName="APP_NOTICE_LIST"/>
      </div>
      <div class="area_right">
        <Button
          btnSize="medium"
          btnStyle="primary"
          text="수정"
          routeName="APP_NOTICE_MODIFY"/>
      </div>
    </Sticky>
  </PageWithLayout>
</template>

<script>
import PageWithLayout from '@lemontree-ai/lemontree-admin-common-front/components/layout/PageWithLayout';
import TableView from '@lemontree-ai/lemontree-admin-common-front/components/common/table/TableView';
import Sticky from '@lemontree-ai/lemontree-admin-common-front/components/common/sticky/Sticky';
import Button from '@lemontree-ai/lemontree-admin-common-front/components/common/button/Button';
// viewModel
import NoticeDetailViewModel from '@/views/landing/notice/viewModel/NoticeDetailViewModel'

export default {
  name:'NoticeDetail',
  components:{
    PageWithLayout,
    TableView,
    Sticky,
    Button,
  },
  beforeMount(){
    const id = this.$route.params.id
    this.viewModel.init(id);
  },
  data(){
    return{
      viewModel: new NoticeDetailViewModel(),
    }
  },
}
</script>